import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// containers
const CardManagement = lazy(() => import("containers/CardManagement"));
const ConvertToEMI = lazy(() => import("containers/ConvertToEMI"));
const EMIConversionResponse = lazy(
  () => import("containers/EMIConversionResponse")
);
const ManageTransaction = lazy(() => import("containers/ManageTransaction/"));
const SuperCardDetails = lazy(() => import("containers/SuperCardDetails"));
const PaymentSuccess = lazy(() => import("containers/PaymentSuccess"));
const CardTransaction = lazy(() => import("containers/CardTransaction"));
const TermsOfService = lazy(() => import("containers/TermsOfService"));
const LoginModal = lazy(() => import("components/LoginModal"));

// protected routes
import { Protected, ProtectedCardInfo } from "protected";

// styles
import "styles/global.scss";
import { useTransaction } from "./context/TransactionContext";
// import Skeleton from "./components/SkeletonPageLoader";

console.log("Check CI CD v-2");

const App = () => {
  const [previousUrl, setPreviousUrl] = useState(null);
  const { setUserDetails } = useTransaction();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("previousUrl", window.location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const storedUrl = sessionStorage.getItem("previousUrl");
    if (storedUrl && storedUrl !== window.location.pathname) {
      setPreviousUrl(storedUrl);
    }
  }, []);

  useEffect(() => {
    const dummyDetails =  {
      phoneNumber: 9717128773,
      cardSerNo: "39790",
      deviceId: "4223",
      superMoneyAccountId: "ifnfjjd",
      last4Digits: "0474",
      paymentStatus: false
    };
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {};
      for (const [key, value] of urlParams.entries()) {
        queryParams[key] = value;
      }
    const context = Object.keys(queryParams).length ? queryParams : dummyDetails;


    if (context) {
      setUserDetails({
        phoneNumber: context.phoneNumber,
        cardSerNo: context.cardSerNo,
        deviceId: context.deviceId,
        superMoneyAccountId: context.superMoneyAccountId,
        last4Digits: context.last4Digits
      });
      window.contextDetails = context;
    }
    sessionStorage.removeItem("transactionScrollPosition");
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/super-card-details" element={<SuperCardDetails />} />
          <Route path="/" element={<SuperCardDetails />} />
          {/* <Route path="/" />
          <Route path="/super-card" element={<CardManagement />} />
          <Route path="/card-transactions" element={<CardTransaction />} />
          <Route element={<Protected />}>
            <Route path="/convert-to-emi" element={<ConvertToEMI />} />
            <Route path="/emi-conversion" element={<EMIConversionResponse />} />
            <Route path="/manage-transaction" element={<ManageTransaction />} />
            <Route element={<ProtectedCardInfo />}>
              <Route
                path="/super-card-details"
                element={<SuperCardDetails />}
              />
            </Route>
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/last-statement" element={<CardTransaction />} />
            <Route path="/terms-of-services" element={<TermsOfService />} />
          </Route>
          {previousUrl && (
            <Route path="*" element={<Navigate to={previousUrl} />} />
          )} */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
