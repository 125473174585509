import { createContext, useState, useContext } from "react";

const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const [transactions, setTransactions] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(2);
  const [numberOfDates, setNumberOfDates] = useState(0);
  const [isLogin, setIsLogin] = useState(false);
  const [userDetails, setUserDetails] = useState({
    phoneNumber: 0,
    cardSerNo: "0",
    deviceId: "0",
    superMoneyAccountId: "",
    last4Digits: 0
  });
  const [paymentStatus, setPaymentStatus] = useState(false);

  const addTransaction = (newTransaction) => {
    setTransactions((prevTransactions) => [
      ...prevTransactions,
      ...newTransaction
    ]);
  };

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        addTransaction,
        setCurrentMonth,
        currentMonth,
        setTransactions,
        numberOfDates,
        setNumberOfDates,
        setIsLogin,
        isLogin,
        userDetails,
        setUserDetails,
        setPaymentStatus,
        paymentStatus
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => useContext(TransactionContext);
